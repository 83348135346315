@use "../../styles/shared.module.scss";

.title {
    font-size: 18px;
}

.header {
    text-align: left;
}

.image {
    padding: 0px;
}

.list {
    color: #d9d9d9;
    padding-inline-start: 40px;
    -webkit-padding-start: 40px;

    li {
        height: 1.8rem;

        &::marker {
            color: #ffc000;
        }

        &:before {
            content: "";
            padding: unset;
        }
    }
}

.numbered-list {
    padding-top: 30px;
    padding-bottom: 30px;
    -webkit-padding-top: 30px;
    padding-inline-start: 10px;
    -webkit-padding-start: 10px;

    .list-item {
        padding-top: 10px;
        display: flex;

        .circle {
            display: flex;
        }
        .list-text {
            transform: translateY(3px);
        }
    }
}

.ready-to-start {
    height: 20px;
    padding-bottom: 10px;
    font-size: 22px;
}

.claim-code {
    width: 90%;
    input {
        color: #282828;
        background-color: white;
        border-radius: 8px;
        text-align: center;
    }
}

.claim-code-wrapper {
    padding-top: 10px;
    padding-bottom: 20px;
    & > div {
        display: flex;
        margin: auto;
    }
}

.inputText {
    color: 'rgba(0,0,0,0.87)';
    font-size: '16px';
    letter-spacing: '0.5px';
    line-height: '28px';
    text-align: 'center';
}

.label {
    color: orange;
    text-align: center;
    background-color: yellow;
}

.disabled {
    pointer-events: none;
    color: lightgray;
    background-color: gray;
}

.error-label {
    font-size: 16px;
    text-align: center;
    letter-spacing: 1px;
}

.progress {
    &>svg {
        color: #ffc000 !important;
    }
}
