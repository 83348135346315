.roboto {
    font-family: Roboto, Helvetica, sans-serif, -apple-system;

    button {
        font-family: Roboto, Helvetica, sans-serif, -apple-system;
    }
}

.container {
    min-width: 355px;
    position: relative;
    font-family: Roboto, Helvetica, sans-serif, -apple-system;
}

.overlay {
    background-image: url("/images/background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    background-color: black;
    height: 1500px;
}

.contents {
    font-family: Roboto, Helvetica, sans-serif, -apple-system;
    font-size: 26px;
        background-color: black;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 75%;
    height: -webkit-fill-available;
    height: 100%;
}

.header {
    font-size: 26px;
    padding: 10px;
    text-align: center;
    line-height: 1.25em;
}

.title {
    color: white;
    padding: 10px;
    letter-spacing: 1px;
    font-weight: 600;
    .underline {
        text-decoration: underline;
    }
}

.sub-title {
    padding: 10px;
    color: #d9d9d9;
    text-align: center;
}

.image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 1000px;
    padding: 10px;
}

.centered {
    width: fit-content;
    margin: auto;
    text-align: center !important;
}

.button {
    background-color: #ffc000;
    color: black;
    border-radius: 100px;
    width: 200px;
    vertical-align: middle;
    height: 40px;
    cursor: pointer;
}

.list {
    color: #d9d9d9;
    padding-inline-start: 5px;
    li {
        height: 1.4rem;

        &::marker {
            // color: transparent;
            padding-left: 5px;
        }
    }
}

.list {
    li:before {
        // content: url("/images/change_history.png");
        padding-right: 10px;
        padding-left: -10px;
    }
}

.white {
    color: white !important;
}

.yellow {
    color: #ffc000;
}

.bold {
    font-weight: bold;
}

.offwhite {
    color: #D9D9D9 !important;
}